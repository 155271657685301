export default {
    permission: {
        admin: 'Administrateur',
        guest: 'Non connecté',
        declarant: 'Déclarant',
        cle: 'Participant CLE',
        animateur_cle: 'Animateur CLE',
        admin_cle: 'Administrateur CLE',
        sdis: 'SDIS',
        ddt: 'DDT',
        mairie: 'Mairie',
        onf: 'ONF',
        cdbd: 'CDBD',
        consultation: 'Consultation',
        consultation_manage_users: 'Consultation & Gestion des utilisateurs',

        declaration_list_show_derogation_column: 'Voir la colonne dérogation dans la liste des déclarations',
        declaration_list_show_bd_column: 'Voir la colonne brulâge dirigé dans la liste des déclarations',

        config_show: 'Récupérer la configuration du site',

        config_update: 'Mettre à jour la configuration',

        availableLayer_index: 'Récupérer les couches cartographiques disponibles',

        availableLayer_show: 'Afficher les couches cartographiques disponibles',

        processing_index: 'Lister les exports',

        processing_show: 'Afficher les exports',

        processing_update: 'Mettre à jour les exports',

        user_page: 'Voir la page des utilisateurs',

        user_index: 'Lister les utilisateurs',

        user_show: 'Afficher les utilisateurs',

        user_store: 'Enregistrer un utilisateur',

        user_update: 'Mettre à jour un utilisateur',

        user_reset_password: 'Réinitialiser le mot de passe d\'un utilisateur',

        user_destroy: 'Supprimer un utilisateur',

        user_restore: 'Restaurer un utilisateur',

        role_index: 'Lister les rôles',

        organization_edit_user: 'Éditer son organisation',

        organization_index: 'Lister les organisations',

        organization_show: 'Afficher les organisations',

        organization_store: 'Enregistrer une organisation',

        organization_update: 'Mettre à jour une organisation',

        organization_destroy: 'Supprimer une organisation',

        organization_index_all: 'Lister toutes les organisations',

        organization_page: 'Lister toutes les organisations',

        organization_write_code: 'Écrire le code de l\'organisation',

        organization_write_name: 'Écrire le nom de l\'organisation',

        organization_write_type: 'Écrire le type de l\'organisation',

        organization_write_communes: 'Écrire les communes de l\'organisation',

        organization_write_phone: 'Écrire le numéro de téléphone de l\'organisation',

        organization_write_policePhone: 'Écrire le numéro de téléphone de la police de l\'organisation',

        organization_write_onfPhone: 'Écrire le numéro de téléphone de l\'ONF de l\'organisation',

        organization_write_mayor: 'Écrire le nom du maire de l\'organisation',

        organization_write_refFirstname: 'Écrire le prénom du référent de l\'organisation',

        organization_write_refLastname: 'Écrire le nom du référent de l\'organisation',

        cle_write_dateCle: 'Écrire la date de la clé',

        organization_write_cle: 'Écrire la clé de l\'organisation',

        declaration_page: 'Voir la page des déclarations',

        declaration_index: 'Lister les déclarations',

        declaration_show: 'Afficher les déclarations',

        declaration_store: 'Enregistrer une déclaration',

        declaration_update: 'Mettre à jour une déclaration',

        declaration_destroy: 'Supprimer une déclaration',

        declaration_display_all: 'Afficher toutes les déclarations',

        declaration_export: 'Exporter les déclarations',

        declaration_show_insTructionTabs: 'Afficher les onglets d\'instruction des déclarations',

        declaration_write_mayorOpinion: 'Écrire l\'avis du maire sur les déclarations',

        declaration_write_mayorDecision: 'Écrire la décision du maire sur les déclarations',

        declaration_write_mayorConform: 'Écrire la conformité du maire sur les déclarations',

        declaration_write_mayorCall: 'Écrire l\'appel du maire sur les déclarations',

        declaration_write_mayorValidationDate: 'Écrire la date de validation du maire sur les déclarations',

        declaration_write_ddtOpinion: 'Écrire l\'avis de la DDT sur les déclarations',

        declaration_write_ddtDecision: 'Écrire la décision de la DDT sur les déclarations',

        declaration_write_onfPreconisation: 'Écrire la préconisation de l\'ONF sur les déclarations',

        declaration_write_onfCall: 'Écrire l\'appel de l\'ONF sur les déclarations',

        declaration_write_clePreconisation: 'Écrire la préconisation de la clé sur les déclarations',

        declaration_write_cleDecision: 'Écrire la décision de la clé sur les déclarations',

        declaration_write_cdbdOpinion: 'Écrire l\'avis du CDBD sur les déclarations',

        declaration_write_cdbdDecision: 'Écrire la décision du CDBD sur les déclarations',

        declaration_write_cdbdStakes: 'Écrire les enjeux du CDBD sur les déclarations',

        declarationComment_index: 'Lister les commentaires des déclarations',

        declarationComment_show: 'Afficher les commentaires des déclarations',

        declarationComment_store: 'Enregistrer un commentaire sur une déclaration',

        declarationComment_update: 'Mettre à jour un commentaire sur une déclaration',

        declarationComment_destroy: 'Supprimer un commentaire sur une déclaration',

        declarationDocument_index: 'Lister les documents des déclarations',

        declarationDocument_show: 'Afficher les documents des déclarations',

        declarationDocument_store: 'Enregistrer un document sur une déclaration',

        declarationDocument_update: 'Mettre à jour un document sur une déclaration',

        declarationDocument_destroy: 'Supprimer un document sur une déclaration',

        declarationDocument_destroy_all: 'Supprimer tous les documents sur une déclaration',

        declarationDocument_download: 'Télécharger un document d\'une déclaration',

        declarationDocument_getThumbnail: 'Obtenir une vignette d\'un document d\'une déclaration',

        declarationTypes_index: 'Lister les types de déclarations',

        changeLog_page: 'Voir la page des journaux de modification',

        changeLog_index: 'Lister les journaux de modification',

        commune_index: 'Lister les communes',

        commune_show: 'Afficher les communes',

        commune_store: 'Enregistrer une commune',

        commune_update: 'Mettre à jour une commune',

        commune_destroy: 'Supprimer une commune',

        profile_show: 'Afficher le profil',

        profile_update: 'Mettre à jour le profil',

        configuration_index: 'Lister les configurations',

        homepage_update: 'Mettre à jour la page d\'accueil',

        map_zsm_index: 'Voir la couche cartographique ZSM',

        declaration_preconisation_button: 'Bouton de préconisation des déclarations',

        declaration_copy_with_notice_button: 'Bouton de copie avec avis des déclarations',

        declaration_write_burns: 'Écrire les brûlages des déclarations',

        declaration_read_burns: 'Lire les brûlages des déclarations',

        declaration_write_morePlannedBurns: 'Écrire plus de brûlages planifiées des déclarations',

        declaration_read_morePlannedBurns: 'Lire plus de brûlages planifiées des déclarations',
    },
}
